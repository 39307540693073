import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—January 2009" />
	<NewsHeader />
    <h2>January 2009</h2>



	<h3 className="blogdate">
	Tuesday, January 27, 2009
	</h3>

	<h4 className="blogitemtitle">
	17.02.06 available at Play, Calgary
	</h4>
		<div className="blogitembody">
		<p>Attention Calgary:</p>

<p>
  I forgot to announce that copies of <i><span>Seventeen Oh-Two Oh-Six</span></i>
  are in stock at Play Entertainment in Market Mall...
</p>
<p>
  Buying CD's is still <i><span>very</span></i> cool... You can phone the
  store at 403-202-1774.
</p>
<p>
  Rad,
</p>
<p>
  Jesse
</p>
		</div>
		<div className="blogitemfooter">
		<p>posted by Jesse @ 17:00 +1300</p>
		</div>


	<h3 className="blogdate">
	Tuesday, January 27, 2009
	</h3>

	<h4 className="blogitemtitle">
	Video - A Traveller's Song
	</h4>
		<div className="blogitembody">
		<p>
		I've just uploaded my first (long overdue) video from my 2006 release <i><span>Seventeen
Oh-Two Oh-Six</span></i>. The video was fun to make, gathering scenes from
the West Coast and Abel Tasman of New Zealand (South Island). <i><span>A
Traveller's Song</span></i> seemed like a naturally fitting song for the
trip.
</p>
<p>
  I've uploaded the video to ILike and Youtube.
</p>
<ul>
  <li>
	<a href="//www.ilike.com/artist/Jesse+Rivest/videos/284624930">http://www.ilike.com/artist/Jesse+Rivest/videos/284624930</a>
  </li>
  <li>
	<a href="//www.youtube.com/watch?v=C4vxF_TuPBM&fmt=18">http://www.youtube.com/watch?v=C4vxF_TuPBM&amp;fmt=18</a>
  </li>
</ul>
<p>
Or you can find it on this Facebook page (see the videos section).
</p>

<ul>
  <li>
	<a href="//apps.facebook.com/ilike/artist/Jesse+Rivest">http://apps.facebook.com/ilike/artist/Jesse+Rivest</a>
  </li>
</ul>
<p>Enjoy!</p><p>Jesse</p>
		</div>
		<div className="blogitemfooter">
		<p>posted by Jesse @ 16:57 +1300</p>
		</div>





	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
